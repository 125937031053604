/* ScrollToTopButton.css */
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background-color: #CCCCCC;
    color: black;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
  }
  
