body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
  }
  
  header {
    background-color: #000;
    color: #fff;
    padding: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: #fff;
  }
  nav { text-align: center;
  justify-content: center;}
  nav a:hover {
    color: #ccc;
  }
  
  main {
    margin-top: 20px;
  }
  
  a:hover {
    color: #ccc;
    text-decoration: underline;
  }
  a:visited {
    color: #AAA ;
  }
  section {
    padding: 20px;
  }
  
  #sobre {
    background-color: #ccc;
  }
  #sobre a {color: black;}
  
  #portfolio {
    background-color: #eee;
  }
  
  #contato {
    background-color: #ddd;
  }
  #redes {
    background-color: #333;
    color: white;
  }
  #projetos {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #projetos li a {
    display: block;
    text-decoration: none;
  }
  
  #projetos li a img {
     float: left;
     max-width: 100%;
     height: auto;
  }
  
  
  footer {
    background-color: #000;
    color: white;
    padding: 20px;
    text-align: center;
}
  #foto {
    border-radius: 30px;
    width: 300px;
    height: auto;
    box-shadow: #AAA 10px 10px;;
  }
  #portfolioimg {
    max-width: 100%;
    height: auto;
  }
  input, textarea {
    background-color: #CCCCCC;
    border:#000 1px solid;
    
  }
  @media only screen and (max-width: 600px) {
    #portfolioimg {
      max-width: 500px;
      height: auto;
    }
   }

  #msg {
    width: 400px;
    height: 220px;
  }
  .projetos a { color: #000; text-decoration: none;}
  .projetos a:hover { color: #CCC; text-decoration: underline;}
  .projetos a:active { color:active; text-decoration:underline;}